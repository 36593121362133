import React from "react"
import ReactDOM from "react-dom"
import 'typeface-roboto'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import packageJson from '../package.json'

import { ParallaxProvider } from 'react-scroll-parallax'

import Routes from "./routes"
import "./i18n"

class NahibuFront extends React.Component {
	render () {
        return (
            <ParallaxProvider>
                <Routes />
            </ParallaxProvider>
        )
	}
}

// Sentry
const sentry = window.config ? window.config.sentry : null
if (sentry) {
    Sentry.init({
      dsn: sentry,
      release: packageJson.name + "@" + packageJson.version,
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing(),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    })
}

ReactDOM.render(<NahibuFront />, document.getElementById("root"))
